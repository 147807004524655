<template>
	<nav class="breadcrumb is-small has-dot-separator" aria-label="breadcrumbs">
		<ul>
			<li>
				<span class="is-size-6" v-if="current == 'Dashboard'" v-html="$t('alerts.welcome', [title])"></span>
				<router-link v-else id="breadcrumb-dashboard" :to="`/${getInitialRoute}`">{{ title }}</router-link>
			</li>
			<li v-if="parent">
				<a :id="`breadcrumb-${parent.name.toLowerCase()}`" :href="parent.path">
					{{ $tc(parent.meta.title, 2) }}
				</a>
			</li>
			<li class="is-active" v-if="current != 'Dashboard'">
				<a :id="`breadcrumb-${this.$route.name.toLowerCase()}`" :href="this.$route.path" aria-current="page">
					{{ $tc(current, 2) }}
				</a>
			</li>
		</ul>
	</nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Breadcrumb',
	props: {
		links: {
			type: [Array, Object]
		}
	},
	data() {
		return {
			title: process.env.VUE_APP_NAME
		}
	},
	computed: {
		...mapGetters('app', [
			'getInitialRoute'
		]),
		parent() {
			let r = this.$route,
				m = r.matched

			return m.length > 1 ? m[0] : false
		},
		current() {
			let r = this.$route,
				m = r.meta
				
			return m ? m.title : r.name
		}
	}
}
</script>

<template>
	<header class="page__title column is-12-mobile">
		<h1 class="title is-size-3-touch mb-0">
			<!-- prettier-ignore -->
			<span v-if="page == 'Dashboard' && user" v-html="$t('alerts.hello', [user.first_name])"></span>
			<span v-else>{{ title }}</span>
		</h1>
		<Breadcrumb />
	</header>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { mapState } from 'vuex'

export default {
	name: 'Title',
	components: {
		Breadcrumb
	},
	computed: {
		...mapState('user', ['user']),
		page() {
			let r = this.$route
			return r.name
		},
		title() {
			let r = this.$route
			let title = r.meta.title || r.name

			return this.$tc(title, 2)
		}
	}
}
</script>

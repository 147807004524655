<template>
	<div class="notifications">
		<button id="button-notifications" class="page__button notifications__button" @click="open()">
			<svg-icon icon="bell"></svg-icon>
			<span class="notifications__button__icon" v-if="totalNotifications > 0">{{ this.totalNotifications }}</span>
		</button>
		<div class="notifications__list" :class="{ active: opened }" v-on:mouseleave="close()">
			<span class="notifications__head">
				<span>{{ $tc('labels.notification', 2) }}</span>
				<svg @click="close()" class="icon-button stroke" viewBox="0 0 24 24">
					<path d="M4.222 4.222l15.556 15.556M4.222 19.778L19.778 4.222"></path>
				</svg>
			</span>
			<ul v-if="notifications.length > 0" class="scroll">
				<li v-for="(n, i) in notifications" :key="i" @click="actionNotification(n)" :style="n.url ? 'cursor: pointer;' : ''">
					<span class="item" :class="{ read: n.read }">
						<i :class="n.icon"></i>
						<span>
							<p class="notifications__list__description">{{ format(n.created_at) }} • {{ timeTo(n.created_at) }}</p>
							<h3 class="notifications__list__title">{{ n.title }}</h3>
						</span>
					</span>
				</li>
			</ul>
			<Results v-else title="" svg="check-circle" :message="$t('alerts.notifications')" />
		</div>
		<span class="header__overlay" :class="{ 'opened': opened }"></span>
	</div>
</template>

<script>
import Icon from '@/components/Icon'
import Results from '@/components/Results'
import Api from '@/services/api'
import '@/mixins/date'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
	name: 'Notifications',
	components: {
		Results,
		'svg-icon': Icon
	},
	data() {
		return {
			opened: false,
			user: {}
		}
	},
	methods: {
		...mapActions('user', [
			'getNotifications',
			'subscribeNotification'
		]),
		...mapMutations('user', [
			'setTotalNotifications',
			'setActionUrl'
		]),
		open() {
			this.opened = true
			this.clearNotifications()
		},
		close() {
			this.opened = false
			this.clearNotifications()
		},
		async clearNotifications() {
			try {
				await Api.put('notification/clearNotifications')
				this.setTotalNotifications(0)
			} catch (e) {
				console.log(e)
			}
		},
		actionNotification(n) {
			let urlCurrent = location.href.split('/')
			let urlAction = n.url.split('/')

			const currentEndpoint = urlCurrent[3]
			const actionEnpoint = urlAction[1]

			if (currentEndpoint != actionEnpoint) {
				this.$router.push(n.url)
			} else if (urlAction.length > 2) {
				this.$router.push(n.url).catch(() => {})
				this.setActionUrl(true)
			}
		}
	},
	mounted() {
		this.subscribeNotification()
	},
	computed: {
		...mapState('user', [
			'notifications',
			'totalNotifications'
		])
	}
}
</script>

import router from '@/router'

export function checkPermission(permissions) {
    const endpoint = window.location.href.split('/')[3]
    const permission = permissions.find(e => e.slug === endpoint.toLowerCase())

    if (permission && !permission.role.read) {
        router.push('/error')
    }
}
